.content-wrapper {
    background-color: #ffffff !important;
}

[class*=sidebar-dark-] {
    background-color: #2a5792;
}

a {
    text-decoration: none !important;
}

.responsive {
    max-width: 100% !important;
    height: auto;
}

.btn-primary {
    color: #fff;
    background-color: #2a5792;
    border-color: #2a5792;
}

div.dataTables_wrapper div.dataTables_length select {
    width: 30% !important;
    display: inline-block;
}

.ck-file-dialog-button {
    display: none;
}

.select2 {
    width:100%!important;
}

.custom-control-input:focus~.custom-control-label::before {
    border-color: #5cb85c !important;
    box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25) !important;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #5cb85c !important;
    background-color: #5cb85c  !important;
}

.custom-control-input:active~.custom-control-label::before {
    background-color: #5cb85c  !important;
    border-color: #5cb85c  !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #5cb85c  !important;
}

.custom-control-input-green:not(:disabled):active~.custom-control-label::before {
    background-color: green  !important;
    border-color: #5cb85c  !important;
}

.select2-selection__choice__remove {
    color: white !important;
}
.select2-selection__choice{
    background-color: #0079FF !important;
    color: white !important;
}
select[readonly] {
    background: #eee; /*Simular campo inativo*/
    pointer-events: none;
    touch-action: none;
}

.modulo-carregando{
    position: absolute;
    white-space: nowrap;
    top: 45%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-transform: uppercase;
    color: #f5f5f5;

    margin: 0;
    padding: 0;
}
.modulo-carregando:before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #000;
    overflow: hidden;
    animation: animate-carregando 10s linear infinite;
    border-right: 1px solid #000;
}

@keyframes animate-carregando {
    0%
    {
        width: 0;
    }
    50%
    {
        width: 100%;
    }
    100%
    {
        width: 0;
    }
}

@media(max-width: 500px) {
    .modulo-carregando{
        font-size:15px;
    }
}

@media screen and (max-width: 1281px) {
    .btn-cad{
        width: 200px !important;
        font-size: 10px !important;
    }
}
.onoff input.toggle {
    display: none;
}

.onoff input.toggle + label {
    display: inline-block;
    position: relative;
    box-shadow: inset 0 0 0px 1px #d5d5d5;
    height: 20px;
    width: 40px;
    border-radius: 30px;
}

.onoff input.toggle + label:before {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 30px;
    background: rgba(19, 191, 17, 0);
    transition: 0.1s ease-in-out;
}

.onoff input.toggle + label:after {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    top: 0;
    left: 0px;
    border-radius: 30px;
    background: #fff;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.1s ease-in-out;
}

.onoff input.toggle:checked + label:before {
    width: 40px;
    background: #13bf11;
}

.onoff input.toggle:checked + label:after {
    left: 20px;
    box-shadow: inset 0 0 0 1px #13bf11, 0 2px 4px rgba(0, 0, 0, 0.2);
}

#loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(110, 108, 108, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    pointer-events: none;
}

.tabela-loading {
    position: relative; 
}

.form-error-red {
    border: 1px solid red !important;
    padding: 10px !important;
    border-radius: 5px !important;
}

.readonly-select {
    pointer-events: none;
    background-color: #e9ecef;
    color: #495057;
}
