// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom
@import 'custom';

// DataTables plugin
@import "~datatables.net-bs4/css/dataTables.bootstrap4";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4";

@import 'select2';

